<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
      color="danger"
    >{{message}}</vs-alert>
    <br />
    <div class="input-wrap">
      <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        label-placeholder="Email"
        v-model="email"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first('email') }}</span>
    </div>
    <div class="input-wrap">
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        type="password"
        name="password"
        label-placeholder="Password"
        v-model="password"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first('password') }}</span>
    </div>

    <div class="flex flex-wrap justify-between mb-3 input-wrap mt-10">
      <!--<vs-button type="border" @click="registerUser">Register</vs-button>-->
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
    <div class="flex flex-wrap justify-content-center my-5 input-wrap">
      <!--<vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>-->
      <router-link to="/forgot-password">Forgot Password?</router-link>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
      showDismissibleAlert: false,
      message: "",
      deviceId: window.navigator.userAgent,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  created() {
    // console.log(this.$route.name, "testing");
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    loginJWT() {
      if (!this.checkLogin()) return;

      // Loading
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        route: this.$route.name,
        userDetails: {
          email: this.email,
          password: this.password,
        },
        browserDetail: this.deviceId
      };
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((response) => {
          this.$vs.loading.close();
          if (response.data.data.token) {
          const user = this.$store.state.AppActiveUser;
          let currentRole = user.userType;
          if (currentRole == "learningCenter") {
            currentRole = user.userRole;
          }
          this.$acl.change(currentRole);
          switch (currentRole) {
            case "superAdmin":
            case "admin":
              this.$router.push({ path: "/admin/dashboard" });
              break;
            case "centreDirector":
              if (
                this.$route.query.redirect &&
                this.$route.query.redirect != ""
              ) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ path: "/director/dashboard" });
              }
              break;
            case "parent":
            case "guardians":
              this.$router.push({ path: "/parent/dashboard" });
              break;
            case "teacher":
              this.$router.push({ path: "/teacher/documentation" });
              break;
            default:
              this.$router.push({ path: "/" });
              break;
          }
        }else {

          this.$router.push({
            path: "/verify-2fa",
            query: { email: this.email, expiresAt: response.data.data.expiresAt },
          });
        }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.showDismissibleAlert = true;
          //this.message = error.response.data.message;
          this.message = error.message;
          if (error.response.status === 422) {
            this.message = error.response.data.message;
          }
          if (error.status === 406) {
            this.message = error.response.data.message;
          }

        });
      /*.catch(error => {
          this.$vs.loading.close()
          if(error.response){
            if(error.response.status === 406){
              this.$vs.notify({
                title: 'Error',
                text: error.response.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }
          }
          else{
            this.$vs.notify({
              title: 'Error',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }


        })*/
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push("/pages/register").catch(() => {});
    },

    //custom
    /*loginPersonhood() {

          if (!this.checkLogin()) return

          // Loading
          this.$vs.loading()

          const payload = {
              keepMeSignedIn: this.checkbox_remember_me,
              email: this.email,
              password: this.password
          }

          this.$store.dispatch('auth/loginPersonhood', payload)
              .then((user) => {
                  this.$vs.loading.close()
                  this.$acl.change('superAdmin')

                  let userType = user.data.data.userType;
                  let role = user.data.data.userRole;

                  if( userType == "parent"){
                       this.$router.push({ path: '/' })
                  }else if(userType == "teacher"){
                       this.$router.push({ path: '/' })
                  }
                  else if(userType == "learningCenter"){

                      if(role == "centerAdmin"){
                           this.$router.push({ path: '/' })
                      }
                      else if(role == "centerDirector"){
                           this.$router.push({ path: '/' })
                      }
                      else if(role == "leadTeacher"){
                           this.$router.push({ path: '/' })
                      }
                  }
                  else if(userType == "superAdmin" || role == "admin"){
                       this.$router.push({ path: '/admin/dashboard/' })
                  }

                  //this.$router.push('/admin/dashboard/');
              })
              .catch(error => {
                  this.$vs.loading.close()
                  this.$vs.notify({
                      title: 'Error',
                      text: error.message,
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                  })
              })
      },*/
  },
};
</script>
<style lang="scss">
.input-wrap {
  padding: 0 30px;
  @media (max-width: 576px) {
    padding: 0;
  }
}

.login-b {
  display: block;
  width: 100%;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
</style>
