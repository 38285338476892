<template>
  <div class="flex h-screen w-full bg-img vx-row no-gutter justify-center" id="page-login">
    <div class="container">
      <div class="vx-col lg:w-1/2 sm:m-0">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col w-full d-theme-dark-bg border rounded">
                <div class="login-tabs-container border rounded">
                  <div class="vx-card__title">
                    <h1 align="center">Personhood 360 Login</h1>
                  </div>
                  <login-jwt></login-jwt>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoginJwt from "./LoginJWT.vue";

export default {
  components: {
    LoginJwt,
  },
};
</script>

<style lang="scss">
#page-login {
  background: #ffffff;
  .vx-col {
    .w-full {
      width: 100% !important;
    }
    margin: 0 auto !important;
    width: 100%;
    @media (min-width: 576px) {
      width: 75%;
    }
    @media (min-width: 992px) {
      width: 70%;
    }
    @media (min-width: 1200px) {
      width: 67% !important;
    }
    @media (min-width: 1480px) {
      width: 75% !important;
    }
    @media (min-width: 1640px) {
      width: 68% !important;
    }
  }

  .vx-card {
    border-radius: 8px !important;
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.11);
    margin: 60px 0;
    overflow: hidden;
    @media (max-width: 767px) {
      box-shadow: none;
      margin: 30px 0;
      border-radius: 0;
    }
    @media (min-width: 1200px) {
      margin: 80px 0;
    }
    @media (min-width: 1640px) {
      margin: 90px 0;
    }
  }
  .vs-button {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    padding: 15px !important;
    width: 100%;
  }
}

.login-tabs-container {
  padding: 20px;
  width: 100%;
  @media (min-width: 992px) {
    width: 70% !important;
    padding: 0;
  }
  @media (min-width: 1024px) {
    margin: 60px auto !important;
    width: 70% !important;
  }
  @media (min-width: 1200px) {
    margin: 70px auto !important;
    width: 67% !important;
  }
  @media (min-width: 1640px) {
    width: 56% !important;
    margin: 94px auto !important;
  }
  h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
    @media (min-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (min-width: 1640px) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 26px;
    }
  }

  .vs-alert--close {
    margin-top: 8px;
  }
}
</style>
